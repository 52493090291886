<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="充电桩管理"
      left-text="返回"
      :right-text="!state.loading ? '绑定' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="!state.loading && $router.push({ name: 'DeviceForm' })"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <van-index-bar v-else-if="state.items.length > 0" :index-list="[]">
      <div v-for="(item, index) in state.items" :key="index">
        <van-index-anchor :index="item.station_name" />
        <van-cell v-if="!item.devices.length">
          <template #value>
            <span class="text-secondary">无设备</span>
          </template>
        </van-cell>
        <div v-else>
          <van-cell
            v-for="device in item.devices"
            :key="device.id"
            :to="{ name: 'DeviceDetail', query: { id: device.id } }"
            :label="device.pile_code"
            is-link
            center
          >
            <template #title>
              {{ device.name }}
              <span class="text-danger" v-if="device.offline">(离线)</span>
              <span class="text-success" v-else>(在线)</span>
            </template>
          </van-cell>
        </div>
      </div>
    </van-index-bar>
    <van-empty v-else image="network" description="暂无充电桩" />
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { List, Toast, IndexBar, IndexAnchor } from "vant";
import { getDeviceList } from "@/api/device.service";
export default {
  components: {
    [List.name]: List,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
  },

  setup() {
    const state = reactive({
      items: [],
      loading: true,
    });

    const loadPage = async () => {
      try {
        const { result } = await getDeviceList();
        state.items.push(...result.items);
        state.loading = false;
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      loadPage();
    });

    return { state, loadPage };
  },
};
</script>
